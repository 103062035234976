<template>
    <PublicForm :busy="busy" maxWidth="1000" width="auto">
      <div>
         <v-row dense>
           <v-col class="py-8 pb-0">
             <img :src="require('@/assets/img/logo' + (white ? '-white' : '') + '.png')" class="logo" /><br />
           </v-col>
         </v-row>

      <v-form ref="formValidation" v-model="validationValid" @submit.prevent="">
       <v-fade-transition hide-on-leave>
        <div class="stepValidation onboardingForm" v-if="!token && !success && !busy" id="stepValidation">
          <v-row dense v-if="validationError">
            <v-col cols="12">
              <LoginPageError :error="validationError" />
            </v-col>
          </v-row>
         <v-row dense>
           <v-col cols="12">
             <p style="text-align:center">Welcome to the new Breeze Connect partner portal. Partners using the old portal will need to complete this process to access the new portal.<br /> If you have already completed this process, then please <a href="./login">login here</a>. </p>
             <p style="color:red;text-align:center">*Note that completing this process will revoke your access to the old partner portal* </p>
             <p style="text-align:center">To continue please enter your current Breeze Connect partner portal credentials. </p>
           </v-col>
         </v-row>

         <v-row dense v-if="!token">
           <v-col cols="12">
             <v-text-field
                 label="Username"
                 ref="username"
                 name="username"
                 v-model="username"
                 prepend-icon="mdi-account"
                 type="text"
                 placeholder=" "
                 :rules="[
                             (v) => !!v || 'Required',
                         ]"
                 :disabled="busy"
                 v-on:keyup.enter="validateCredentials"
                 required
                 :autofocus="!username"
             />
             <v-text-field
                 id="password"
                 ref="password"
                 label="Password"
                 name="password"
                 placeholder=" "
                 v-model="password"
                 prepend-icon="mdi-lock"
                 :rules="[(v) => !!v || 'Required']"
                 :disabled="busy"
                 type="password"
                 v-on:keyup.enter="validateCredentials"
                 required
                 :autofocus="!!username"
             />
             <v-btn
                 @click="validateCredentials"
                 :loading="busy"
                 color="primary"
                 :disabled="!validationValid"
                 block
                 large
             >Validate</v-btn
             >
             <p class="signupLink"><a href="https://breezeconnect.com.au/sign-up/">No account? Sign up as a new user.</a></p>
           </v-col>
         </v-row>
        </div>
       </v-fade-transition>
      </v-form>
      <v-form ref="formContactDetails" v-model="contactDetailsValid" @submit.prevent="">
       <v-fade-transition hide-on-leave>
         <div class="stepContactDetails onboardingForm" v-if="token && tokenValid" id="stepContactDetails">
           <v-row dense>
             <v-col cols="12">
               <LoginPageError :error="contactDetailsError" />
             </v-col>
           </v-row>
           <v-row dense>
             <v-col cols="12">
               <p class="textContactDetails">
                 Please set a new password and update your contact details.
               </p>
             </v-col>
           </v-row>
           <v-row dense>
             <v-col cols="12">
               <p><strong>Login Details</strong></p>
             </v-col>
           </v-row>
           <v-row dense>
             <v-col cols="12" sm="4">
               <v-text-field
                   v-model="loginDetails.username"
                   label="Username"
                   outlined
                   hint="Unique name used for login."
                   :rules="rules.required"
               />
             </v-col>
             <v-col cols="12" sm="4">
               <PasswordField
                   v-model="loginDetails.password"
                   label="Password"
                   outlined
               />
             </v-col>
             <v-col cols="12" sm="4">
               <PasswordField
                   v-model="loginDetails.passwordCheck"
                   label="Retype Password"
                   outlined
                   :errorMessages="passwordCheckError"
               />
             </v-col>
           </v-row>
           <v-row dense>
             <v-col cols="12">
               <p><strong>Contact Details</strong></p>
               <ContactDetailsForm v-model="contactDetails" :hints='{"email":"Account notifications will be sent to this address."}'/>
             </v-col>
           </v-row>

           <div class="agreement-div">
             <p><strong>Please read and acknowledge the Terms & Conditions below.</strong></p>
             <p>As a Breeze Connect Partner:</p>
             <v-row dense>
               <v-col cols="12">
                 <v-checkbox
                     :disabled="busy"
                     class="pa-0 ma-0"
                     v-model="agreementTerms"
                     :rules="rules.required"
                 >
                   <div slot='label'>* I understand I am bound by the Terms & Conditions laid out in the Breeze Partner agreement.</div>
                 </v-checkbox>
               </v-col>
               <v-col cols="12">
                 <v-checkbox
                     :disabled="busy"
                     class="pa-0 ma-0"
                     v-model="agreementTelConsumerProtection"
                     :rules="rules.required"
                 >
                   <div slot='label'>* I am aware of my obligations under the <a
                       target="_blank"
                       @click.stop
                       href="https://www.commsalliance.com.au/Documents/all/codes/c628"
                                          >C628:2019 TELECOMMUNICATIONS CONSUMER PROTECTIONS (TCP) CODE</a> and I agree to abide by these in all my dealings with customers.

                   </div>
                 </v-checkbox>
               </v-col>
               <v-col cols="12">
                 <v-checkbox
                     :disabled="busy"
                     class="pa-0 ma-0"
                     v-model="agreementDisadvantageTraining"
                     :rules="rules.required"
                 >
                   <div slot='label'>* I have reviewed the <a
                       target="_blank"
                       @click.stop href="https://www.accc.gov.au/system/files/217_%20BS%20Don't%20take%20advantage%20_FA_Web_Nov-2014.pdf">don’t take advantage of disadvantage training PDF</a>, and will ensure I follow all my responsibilities outlined in the training.</div>
                 </v-checkbox>
               </v-col>
               <v-col cols="12">
                 <v-checkbox
                     :disabled="busy"
                     class="pa-0 ma-0"
                     v-model="agreementHardshipPolicy"
                     :rules="rules.required"
                 >
                   <div slot='label'>* I am aware of Breeze Connects <a
                       target="_blank"
                       @click.stop href="https://static.breezeconnect.com.au/documents/policies/BreezeConnectFinancialHardshipPolicy.pdf">financial hardship policy</a> and will provide them to the customer upon request.</div>
                 </v-checkbox>
               </v-col>
             </v-row>
             <v-btn
                 @click="saveDetails"
                 :loading="busy"
                 color="primary"
                 :disabled="!contactDetailsValid || !contactDetails || passwordCheckError || !loginDetails.passwordCheck"
                 block
                 large
             >Save Details</v-btn>
           </div>
         </div>
       </v-fade-transition>
      </v-form>
        <v-form ref="formSucces">
          <v-fade-transition hide-on-leave>
            <div class="stepSuccess onboardingForm" v-if="userId && success" id="stepSuccess" >
              <v-row dense>
                <v-col cols="12">
                  <p>Success. Redirecting to the <a :href="'/login?username='+successData.username">login page</a>.</p>
                </v-col>
              </v-row>
            </div>
          </v-fade-transition>
        </v-form>
      </div>
    </PublicForm>

</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import PublicForm from '../../components/pieces/Public/PublicForm';
import LoginPageError from '../../components/pieces/Public/LoginPageError';
import BreezeLogo from '../../components/pieces/Public/BreezeLogo';
import apiMixin from '../../mixins/apiMixin';
import ContactDetailsForm from "../../components/pieces/Forms/ContactDetailsForm";
import PasswordField from "../../components/pieces/PasswordField";
import store from "../../store";

export default {
    name: 'OnboardingUsers',
    mixins: [apiMixin],
  components: {
    PasswordField,
    ContactDetailsForm,
     //BreezeLogo,
     LoginPageError,
     PublicForm,
  },
    data() {
        return {
            token: null,
            tokenValid: false,
            validationValid: false,
            username: '',
            password: '',
            validationError:null,

            userId:null,
            contactDetails:null,
            contactDetailsError:null,
            contactDetailsValid: false,
            loginDetails:{
              passwordCheck:null
            },
            passwordCheckError: null,

            agreementTerms:false,
            agreementTelConsumerProtection:false,
            agreementDisadvantageTraining:false,
            agreementHardshipPolicy:false,

            success:false,
            successData:{},

            error: '',
            busy: false,


          rules: {
            required: [
              v => !!v || 'Required',
            ],
            passwordCheck:[
                v => !!v || 'Required',
                v => v != this.loginDetails.password || 'Does not match password',
            ],
          },
        };
    },
    props: {
      white: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      async validateCredentials(){
        if (!this.validationValid){
          return false;
        }
        this.busy = true;
        const response = await this.Api.send('post','onboarding/validate/credentials', {
          username: this.username,
          password: this.password,
        });
        if (response.success) {
          this.validationError = null;
          this.token = response.data.token;
          this.tokenValid = true;
          this.loginDetails.username = this.username;
          //this.loginDetails.password = this.password;
          this.username = null;
          this.password = null;
          this.busy = false;
        }else{
          if(response.error != '' && response.error != undefined){
            this.validationError= response.error;
          }else{
            this.validationError = response.data.description;
          }
          this.busy = false;
          await this.$nextTick();
          this.scrollToElement('stepValidation');
        }
      },
      async saveDetails( ){
        this.$refs.formContactDetails.validate();
        if (!this.contactDetails && this.token && this.tokenValid){
          return false;
        }
        this.busy = true;
        let params = {
          obt: this.token,
          loginDetails: {"password":this.loginDetails.password, "username":this.loginDetails.username},
          contactDetails: this.contactDetails,
          agreementTerms: this.agreementTerms,
          agreementTelConsumerProtection: this.agreementTelConsumerProtection,
          agreementDisadvantageTraining: this.agreementDisadvantageTraining,
          agreementHardshipPolicy: this.agreementHardshipPolicy,
        };

        const response = await this.Api.send('post','onboarding/user', params, true);

        if (response.success) {
          this.contactDetailsError = null;
          this.userId = response.data.userId;
          this.successData.username = this.loginDetails.username;
          this.token = null;
          this.loginDetails = null;
          this.contactDetails = null;
          this.agreementTerms = null;
          this.agreementTelConsumerProtection = null;
          this.agreementDisadvantageTraining = null;
          this.agreementHardshipPolicy = null;

          this.success = true;
          setTimeout( () => this.$router.push({ path: '/login',query: { username: this.successData.username}}), 2000);

          //await this.$router.push({ path: 'login', query: { username: this.successData.username}});
        }else{
          if(response.status == 403){
            //validation error. Reset token.
            if(this.userId == null){
              //return to previous step
              this.token = null;
              this.tokenValid = false;
              this.validationError = response.errorDetail;
              this.scrollToElement('stepContactDetails');
            }else{
              //display overlay message
              store.commit('showError', response.errorDetail);
            }
          }else if(response.error != '' && response.error != undefined){
            this.contactDetailsError = response.error;
            this.scrollToElement('stepContactDetails');
          }else if(response.data.description != '' && response.data.description != undefined){
            this.contactDetailsError = response.data.description;
            this.scrollToElement('stepContactDetails');
          }else{
            this.contactDetailsError = 'Server Error. Contact admin.';
            this.scrollToElement('stepContactDetails');
          }
        }
        this.busy = false;
      },
      async validateQueryToken(token){
        this.busy = true;
        const response = await this.Api.send('post','onboarding/validate/token', {
          obt: token
        });
        if (response.success) {
          this.token = token;
          this.userId = response.data.user.id;
          this.loginDetails.username = response.data.user.username;
          this.tokenValid = true;

          if(response.data.contact != null){
            this.contactDetails = {};
            this.contactDetails.salutation = response.data.contact.salutation;
            this.contactDetails.firstname = response.data.contact.firstname;
            this.contactDetails.lastname = response.data.contact.lastname;
            this.contactDetails.phone1 = response.data.contact.phone1;
            this.contactDetails.phone2 = response.data.contact.phone2;
            this.contactDetails.email = response.data.contact.email;
          }

        }else{
          this.token = null;
          this.tokenValid = false;
        }
        this.busy = false;
      },
      scrollToElement(id){
        window.scrollTo({
          top: document.getElementById(id).offsetTop,
          left: 0,
          behavior: "smooth",
        });
      },

    },
  watch: {
    passwordCheck() {
      if(this.loginDetails.passwordCheck == null || this.loginDetails.passwordCheck == this.loginDetails.password){
        this.passwordCheckError = null;
      }else if(this.loginDetails.passwordCheck != this.loginDetails.password){
        this.passwordCheckError = "Must match password.";
      }
    }
  },
  computed: {
    passwordCheck() {
      return this.loginDetails.passwordCheck;
    }
  },
    created() {
      const queryToken = this.$route.query.obt;
      if(queryToken != null){
        this.validateQueryToken(queryToken);
      }
    },

};
</script>
<style scoped>
.logo{
  max-height:3.2rem;
  width:auto;
  margin-left:auto;
  margin-right:auto;
  display:block;
}


.stepValidation{
  max-width:27rem;
  padding-bottom:1rem;
}

.textContactDetails{
  text-align:center;
}
.onboardingForm{
  padding:2em;
}

.agreement-div{
  margin-left:auto;
  margin-right:auto;
  max-width:600px;
}

.signupLink{
  text-align:center;
  margin-top:1rem;
}
</style>